.confirmDelete {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.confirmDeleteButton {
	margin-top: 30px;
	padding: 5px 10px;

	background-color: hsl(0deg 60% 55%);
	border-radius: 10px;
	cursor: pointer;
}

.confirmDeleteButton:hover {
	background-color: hsl(0deg 60% 60%);
}

.confirmDeleteButton:active {
	background-color: hsl(0deg 50% 50%);
}

.confirmDeleteButton>h1 {
	font-size: 20px;
}