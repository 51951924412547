/* Save Changes Button */

.optionsModalSaveChanges {
	padding: 5px 10px;

	--accent-hue: 220deg;
	background-color: hsl(var(--accent-hue) 40% 40%);
	border-radius: 10px;
	cursor: pointer;
}

.optionsModalSaveChanges:hover {
	background-color: hsl(var(--accent-hue) 40% 45%);
}

.optionsModalSaveChanges:active {
	background-color: hsl(var(--accent-hue) 40% 35%);
}