.App {
	width: 100%;
	height: 100%;

	background-color: hsl(220deg 5% 12%);

	display: grid;
	grid-template-areas:
		"header header"
		"view   sidebar";
	grid-template-rows: 50px 1fr;
	grid-template-columns: 1fr 400px;
}

.AppHideSidebar {
	grid-template-areas:
		"header"
		"view";
	grid-template-columns: 1fr;
}

/* Header */

header {
	padding: 0 15px;

	background-color: hsl(220deg 5% 18%);
	border-bottom: 1px solid #fff3;

	grid-area: header;

	display: flex;
	align-items: center;
}

header h1 {
	font-size: 24px;
}

/* Dropdown Select */

.dropdownSelect {
	width: fit-content;
	padding: 5px 10px;

	background-color: hsl(220deg 5% 24%);
	border-radius: 50px;
	cursor: pointer;

	display: flex;
	align-items: center;
}

.dropdownSelect:hover {
	background-color: hsl(220deg 5% 28%);
}

.dropdownSelect>h1,
.dropdownSelect>span {
	font-size: 18px;
}

.dropdownSelect>h1 {
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropdownSelect:hover>h1,
.dropdownSelect:hover>span {
	color: #fff;
}

.dropdownSelect>h1,
.dropdownSelect>span {
	font-size: 18px;
	color: #fffc;
}

.dropdownSelect:active {
	background-color: hsl(220deg 5% 24%);
}

.dropdownSelectOpened {
	background-color: hsl(220deg 5% 28%);
}

.dropdownSelectOpened>h1,
.dropdownSelectOpened>span {
	color: #fff;
}

/* Dropdown */

.dropdown {
	position: absolute;
	width: 180px;

	border-radius: 15px;
	box-shadow: 0 0 20px #0005;
}

.dropdown>div,
.dropdown>form {
	height: 22px;
	padding: 5px 10px;

	background-color: hsl(220deg 5% 28%);
	cursor: pointer;

	display: flex;
	align-items: center;
}

.dropdown>div:hover {
	background-color: hsl(220deg 5% 32%);
}

.dropdown>div:active {
	background-color: hsl(220deg 5% 24%);
}

.dropdown h1 {
	width: 100%;

	font-size: 18px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropdown>div>span {
	font-size: 20px;
}

.dropdown>div>span:hover {
	color: #f88;
}

.dropdown>div>span:active {
	color: #f55;
}

.dropdown>div:first-child,
.dropdown>form:first-child {
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}

/* Dropdown Input */

.dropdown>form {
	height: 35px;

	border-bottom-right-radius: 15px;
	border-bottom-left-radius: 15px;
	cursor: unset;

	display: flex;
	align-items: center;
	gap: 5px;
}

.dropdown>form>input {
	padding: 5px 10px;

	font-size: 14px;
	border-radius: 15px;
}

.dropdown>form>span {
	color: #fffa;
	cursor: pointer;
}

.dropdown>form>span:hover {
	color: #fff;
}

.dropdown>form>span:active {
	color: #fff8;
}

/* Header - Actions */

.headerActions {
	margin-left: auto;

	display: flex;
	align-items: center;
	gap: 10px;
}

.headerTab,
.headerSelectDataset {
	height: 25px;
	padding: 5px 10px;

	border-radius: 50px;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 5px;
}

.headerTab>span,
.headerTab>h1 {
	font-size: 20px;
	color: #fff9;
}

.headerTab:hover>span,
.headerTab:hover>h1 {
	color: #fffc;
}

.headerTab:active>span,
.headerTab:active>h1 {
	color: #fff7;
}

.headerTabSelected {
	background-color: #fff1;
}

.headerTabSelected>span,
.headerTabSelected>h1 {
	color: white !important;
}

/* Views */

.view {
	padding: 20px;
	padding-left: 40px;
	overflow-y: scroll;

	grid-area: view;
}

/* Sidebar */

.sidebar {
	padding: 10px;

	background-color: hsl(220deg 5% 18%);
	border-radius: 10px;
	overflow-y: auto;

	grid-area: sidebar;

	display: flex;
	flex-direction: column;
	gap: 5px;
	flex-grow: 1;
}

.sidebarTitle {
	display: flex;
	align-items: center;
	gap: 5px;
}

.sidebarTitle>h1 {
	font-size: 20px;
}

.sidebarTitle>span {
	color: #fffa;
	cursor: pointer;
}

.sidebarTitle>span:hover {
	color: #fff;
}

.sidebarTitle>span:active {
	color: #fff8;
}

.sidebar>h2 {
	margin-top: 10px;

	font-size: 18px;
}

.sidebarCheckbox>h3 {
	font-size: 14px;
}

.sidebarRow {
	display: flex;
	align-items: center;
	gap: 10px;
}

.sidebarCheckbox {
	cursor: pointer;

	display: flex;
	align-items: center;
	gap: 3px;
}

.sidebarRow>input[type="checkbox"]:not(:first-child) {
	margin-left: 10px;
}

.sidebar input[type="date"],
.sidebar input[type="time"] {
	height: 20px;
}

.sidebar select {
	padding: 0;
}

.sidebar .textareaWrapper {
	min-height: 2.5em;

	border-radius: 5px;
	overflow: hidden;

	display: flex;
	flex-grow: 1;
}

.sidebar textarea {
	resize: none;

	flex-grow: 1;
}

.sidebar input[type="submit"],
.sidebar input[type="button"] {
	margin-top: 10px;

	--accent-hue: 220deg;
	background-color: hsl(var(--accent-hue) 45% 50%);
	font-weight: bold;
	cursor: pointer;
}

.sidebar input[type="submit"]:hover,
.sidebar input[type="button"]:hover {
	background-color: hsl(var(--accent-hue) 45% 53%);
}

.sidebar input[type="submit"]:active,
.sidebar input[type="button"]:active {
	background-color: hsl(var(--accent-hue) 45% 45%);
}

.sidebar input[type="range"] {
	width: auto;
	appearance: none;
	-webkit-appearance: none;

	background-color: transparent;
	--slider-accent-hue: 0deg;
	--slider-accent-lightness: 65%;
}

.sidebar input[type="range"]::-webkit-slider-runnable-track {
	height: 8px;
	top: 8px;

	border: 1px solid #fff6;
	--hue-saturation: 60%;
	background: linear-gradient(to right, hsl(0deg var(--hue-saturation) 50%), hsl(30deg var(--hue-saturation) 50%), hsl(60deg var(--hue-saturation) 50%), hsl(90deg var(--hue-saturation) 50%), hsl(120deg var(--hue-saturation) 50%), hsl(150deg var(--hue-saturation) 50%), hsl(180deg var(--hue-saturation) 50%), hsl(210deg var(--hue-saturation) 50%), hsl(240deg var(--hue-saturation) 50%), hsl(270deg var(--hue-saturation) 50%), hsl(300deg var(--hue-saturation) 50%), hsl(330deg var(--hue-saturation) 50%), hsl(360deg var(--hue-saturation) 50%));
	border-radius: 10px;
}

.sidebar input[type="range"]::-webkit-slider-thumb {
	appearance: none;
	-webkit-appearance: none;

	width: 15px;
	height: 15px;
	margin-top: -5px;

	border-radius: 7.5px;
	background-color: #f55;
	background-color: hsl(var(--slider-accent-hue) 70% var(--slider-accent-lightness));
}

.sidebarIconButton {
	color: #fffc;
	cursor: pointer;
}

.sidebarIconButton:hover {
	color: #fff;
}

.sidebarIconButton:active {
	color: #fffa;
}