.timelineView {
	padding: 0;

	overflow: hidden;
}

.timelineView>canvas {
	width: 100%;
	height: 100%;
}

/* Event Toooltip */

.tooltip {
	position: absolute;
	width: 380px;
	height: 180px;
	left: 0;
	top: 50px;
	margin: 15px;
	padding: 10px;

	background-color: hsl(220deg 5% 18%);
	border-radius: 5px;
	border: 1px solid #fff3;
}

.tooltip>h2 {
	font-size: 20px;
	margin-bottom: 5px;

	overflow: hidden;

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.tooltip>p {
	overflow: hidden;

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.tooltipNotes {
	margin-top: 9px;

	-webkit-line-clamp: 5;
}