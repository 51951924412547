.eventMonth {
	margin-bottom: 20px;
}

.eventMonth>h2 {
	padding-left: 4px;
	margin-bottom: 2px;

	font-size: 17px;
	text-transform: uppercase;
}

/* Events */

.event {
	padding: 2px 4px;
	font-size: 16px;
	border-radius: 5px;

	cursor: pointer;
}

.event:hover {
	background-color: #fff1;
}

.event:active,
.eventEditing:active {
	color: #fffa !important;
	background-color: #ffffff0a !important;
}

.eventEditing,
.eventEditing:hover {
	color: white;
	background-color: #fff1;
}